// src/context/GameContext.js

import React, { createContext, useState, useEffect } from 'react';

export const GameContext = createContext();

export const GameProvider = ({ children }) => {
  // Какую страницу показываем (dashboard, upgrades, etc.)
  const [page, setPage] = useState('dashboard');

  // Глобальные состояния:
  const [coins, setCoins] = useState(0);
  const [secondsCount, setSecondsCount] = useState(0);
  const [minutesCount, setMinutesCount] = useState(0);
  const [hoursCount, setHoursCount] = useState(0);

  // Допустим, у вас есть multiplier, user, etc.
  const [secondsMultiplier, setSecondsMultiplier] = useState(1);
  const [minutesMultiplier, setMinutesMultiplier] = useState(1);
  const [hoursMultiplier, setHoursMultiplier] = useState(1);

  // Telegram user
  const [tgUser, setTgUser] = useState(null);
  const [notTelegram, setNotTelegram] = useState(false);

  // При монтировании проверяем, есть ли Telegram.WebApp
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      if (tg.initDataUnsafe?.user) {
        setTgUser(tg.initDataUnsafe.user);
      }
      tg.ready();
    } else {
      setNotTelegram(true);
    }
  }, []);

  // Считаем доход в час
  const calculateIncomePerHour = () => {
    return (
      (secondsCount * secondsMultiplier +
        minutesCount * minutesMultiplier * 60 +
        hoursCount * hoursMultiplier * 3600) * 60
    );
  };

  // Загрузка прогресса (GET /api/get_progress/<user_id>)
  const loadProgress = async () => {
    if (!tgUser) {
      alert("Нет информации о Telegram-пользователе!");
      return;
    }
    try {
      const res = await fetch(`/api/get_progress/${tgUser.id}`);
      const data = await res.json();

      if (!res.ok) {
        alert("Ошибка загрузки: " + JSON.stringify(data));
        return;
      }
      // Устанавливаем загруженные значения
      setCoins(data.coins || 0);
      setSecondsCount(data.seconds_count || 0);
      setMinutesCount(data.minutes_count || 0);
      setHoursCount(data.hours_count || 0);

      alert("Прогресс загружен!");
    } catch (err) {
      console.error("Error loading progress:", err);
      alert("Ошибка при загрузке: " + err);
    }
  };

  // Сохранение прогресса (POST /api/save_progress)
  const saveProgress = async () => {
    if (!tgUser) {
      alert("Нет информации о Telegram-пользователе!");
      return;
    }
    // Собираем данные в теле
    const body = {
      user_id: tgUser.id,
      coins,
      // Важный момент: API ждёт seconds_count, minutes_count, hours_count,
      // а у нас в стейте secondsCount, minutesCount, hoursCount
      seconds_count: secondsCount,
      minutes_count: minutesCount,
      hours_count: hoursCount,
      initData: window.Telegram?.WebApp?.initData || ""
    };

    try {
      const res = await fetch('/api/save_progress', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      const data = await res.json();

      if (!res.ok) {
        alert("Ошибка сохранения: " + JSON.stringify(data));
        return;
      }
      alert("Сохранено! " + JSON.stringify(data));
    } catch (err) {
      console.error("Error saving progress:", err);
      alert("Ошибка при сохранении: " + err);
    }
  };

  // Собираем все значения/методы в один объект, чтобы отдать в контекст
  return (
    <GameContext.Provider
      value={{
        page,
        setPage,

        // Важные стейты
        coins,
        setCoins,
        secondsCount,
        setSecondsCount,
        minutesCount,
        setMinutesCount,
        hoursCount,
        setHoursCount,

        secondsMultiplier,
        setSecondsMultiplier,
        minutesMultiplier,
        setMinutesMultiplier,
        hoursMultiplier,
        setHoursMultiplier,

        tgUser,
        notTelegram,

        // Функции
        calculateIncomePerHour,
        loadProgress,
        saveProgress
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
