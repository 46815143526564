// src/components/DebugInitData.js
import React from 'react';

const DebugInitData = () => {
  // Если Telegram WebApp недоступен, выводим сообщение
  if (!window.Telegram?.WebApp) {
    return <div>Telegram WebApp не обнаружен! (возможно, открыто не в Telegram)</div>;
  }

  const data = window.Telegram.WebApp.initData;           // строка с query-параметрами
  const dataUnsafe = window.Telegram.WebApp.initDataUnsafe; // объект

  if (!data) {
    return <div>initData пуст!</div>;
  }

  return (
    <div style={{ backgroundColor: '#eee', padding: '10px', margin: '10px' }}>
      <h3>Debug initData</h3>
      <p><strong>initData:</strong> {data}</p>
      <p><strong>initDataUnsafe:</strong> {JSON.stringify(dataUnsafe)}</p>
    </div>
  );
};

export default DebugInitData;
