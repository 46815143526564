// src/App.js
import React, { useContext } from 'react';
import Dashboard from './components/Dashboard';
import Upgrades from './pages/Upgrades';
import Stats from './pages/Stats';
import Settings from './pages/Settings';
import BottomNav from './navigation/BottomNav';
import TopBar from './components/TopBar';
import './App.css';

// Импортируем контекст и провайдер
import { GameContext, GameProvider } from './context/GameContext';

// Импортируем наш компонент отладки
import DebugInitData from './components/DebugInitData';

function AppContainer() {
  const {
    page,
    setPage,
    coins,
    notTelegram,
    saveProgress,
    loadProgress,
    calculateIncomePerHour
  } = useContext(GameContext);

  // Если открыто не в Telegram, отображаем предупреждение
  if (notTelegram) {
    return <h1>Эта страница работает только внутри Telegram WebApp!</h1>;
  }

  // Выбор страницы
  const renderPage = () => {
    switch (page) {
      case 'dashboard':
        return <Dashboard />;
      case 'upgrades':
        return <Upgrades />;
      case 'stats':
        return <Stats />;
      case 'settings':
        return <Settings />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div>
      {/* Верхняя панель */}
      <TopBar incomePerHour={calculateIncomePerHour()} coins={coins} />

      {/* Кнопки сохранения/загрузки */}
      <div style={{ marginTop: 70, textAlign: 'center' }}>
        <button onClick={saveProgress}>Сохранить прогресс</button>
        <button onClick={loadProgress}>Загрузить прогресс</button>
      </div>

      {/* Основное содержимое */}
      {renderPage()}

      {/* Нижняя навигация */}
      <BottomNav setPage={setPage} />

      {/* Отладочный блок, показывающий initData */}
      <DebugInitData />
    </div>
  );
}

function App() {
  // Оборачиваем всё в GameProvider
  return (
    <GameProvider>
      <AppContainer />
    </GameProvider>
  );
}

export default App;
